import { MenuEntry } from '@pancakeswap-libs/uikit'


const homepage = "https://sishi.finance/"

const config: MenuEntry[] = [
  {
    label: 'sVault',
    icon: 'HomeIcon',
    href: `${homepage}`,
  },
  {
    label: 'sSwap',
    icon: 'TradeIcon',
    href: '/swap',
  },
  {
    label: 'sFarm',
    icon: 'FarmIcon',
    href: `${homepage}farms`,
  },
  {
    label: 'sClass',
    icon: 'VaultIcon',
    href: `${homepage}sclass`,
  },
  {
    label: 'sVote',
    icon: 'GroupsIcon',
    href:'https://snapshot.org/#/sishi.eth',
    newTab: true,
  },
  {
    label: 'sDocs',
    icon: 'GitbookIcon',
    href:'https://docs.sishi.finance',
    newTab: true,
  },
  {
    label: 'Audit',
    icon: 'AuditIcon',
    href:'https://docs.sishi.finance/security/audit',
    newTab: true,
  },
  {
    label: 'Listing',
    icon: 'LayerIcon',
    items: [
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/currencies/sishi-finance/',
        newTab: true,
      },
      {
        label: 'DappRadar',
        href: 'https://dappradar.com/binance-smart-chain/defi/sishi-finance',
        newTab: true,
      },
      {
        label: 'CoinGecko',
        href: 'https://www.coingecko.com/en/coins/sishi-finance',
        newTab: true,
      },
      {
        label: 'Coinpaprika',
        href: 'https://coinpaprika.com/coin/sishi-sishi-token/',
        newTab: true,
      },
    ],
  },
  {
    label: 'Communities',
    icon: 'LayerIcon',
    items: [
      {
        label: 'Telegram',
        href: 'https://t.me/sishi_finance',
        newTab: true,
      },
      {
        label: 'Twitter',
        href: 'https://twitter.com/0xsishi',
        newTab: true,
      },
      {
        label: 'Discord',
        href: 'https://discord.com/invite/aGQsMxmZbK',
        newTab: true,
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/sishi-finance/',
        newTab: true,
      },
      {
        label: 'Medium',
        href: 'https://0xsishi.medium.com/',
        newTab: true,
      },
      {
        label: 'Publish0x',
        href: 'https://www.publish0x.com/sishi-finance',
        newTab: true,
      },
    ],
  },
]

export default config
